import { axios, getError } from "lib"
import { toast } from "react-toastify"
import {
    getBillingsConfig,
    getTransactionsConfig,
    addCreditConfig,
    payAllInvoicesConfig,
    getCurrentCreditBalanceConfig,
    getUserCardsConfig,
    listPaymentGatewaysConfig,
    addCardConfig,
    payConfig,
    getPayUrlConfig,
	getProfile,
	cancelAgreeConfig,
} from "lib/requests"
import {
    getBillsDispatch,
    getTransactionsDispatch,
    getCurrentCreditBalanceDispatch,
    setBillsLoading,
    setGateways,
    setUserCards,
} from "store/Slices"
import { getUserById } from "./UsersActions"
import { authenticationSuccess } from "store/Slices/authSlice"

export const getBills = () => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = getBillingsConfig()

            const res = await axios.get(url, config)

            if (res.status === 200) {
                // Do not show draft invoices to users. Can be altered to show drafts to logged in admins
                // const data = res?.data?.data?.filter((bill) => bill.status > 0)
                const data = res?.data?.data

                dispatch(getBillsDispatch(data))
            }
            dispatch(setBillsLoading(false))
        } catch (e) {
            toast.error(getError(e))
            dispatch(setBillsLoading(false))
        }
    }
}

export const getTransactions = (startDate, endDate, pageNumber, pageSize) => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, data, config } = getTransactionsConfig()
			const otherData = {
				startDate,
				endDate,
				pageNumber,
				pageSize
			}
            const res = await axios.post(url, {...data, ...otherData}, config)
            if (res.status === 200) {
                dispatch(getTransactionsDispatch(res?.data))
            }
            dispatch(setBillsLoading(false))
        } catch (e) {
            toast.error(getError(e))

            dispatch(setBillsLoading(false))
        }
    }
}

export const getCurrentCreditBalance = () => {
    return async (dispatch) => {
        try {
            const { url, config } = getCurrentCreditBalanceConfig()
            const res = await axios.get(url, config)
            if (res.status === 200) {
                dispatch(getCurrentCreditBalanceDispatch(res?.data?.data))
            }
        } catch (e) {
            // toast.error(getError(e))
        }
    }
}

export const payAllInvoices = ({ data, onSuccess, onFail }) => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = payAllInvoicesConfig()
            const res = await axios.post(url, data, config)
            if (res?.status === 200) {
                dispatch(getBills())
                onSuccess && onSuccess()
            } else {
                onFail && onFail()
            }
        } catch (e) {
            toast.error(getError(e))
            dispatch(setBillsLoading(false))
            onFail && onFail()
        }
    }
}

export const addCredit = ({ data, onSuccess, onFail }) => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = addCreditConfig()
            const res = await axios.post(url, data, config)
            if (res?.status === 200) {
                dispatch(getBills())
                onSuccess && onSuccess()
            } else {
                onFail && onFail()
            }
        } catch (e) {
            toast.error(getError(e))
            dispatch(setBillsLoading(false))
            onFail && onFail()
        }
    }
}

export const makePayment = (id, data) => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = payConfig(id)
            const response = await axios.post(url, data, config)
            if (response.status === 200) {
                toast.success("Payment Successful")
                dispatch(getCurrentCreditBalance())
            }
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setBillsLoading(false))
        }
    }
}

export const addCard = (data) => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = addCardConfig()
            const response = await axios.post(url, data, config)
            if (response.status === 200) {
                toast.success("Card Added Successfully")
                dispatch(getUserCards())
                dispatch(getCurrentCreditBalance())
            }
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setBillsLoading(false))
        }
    }
}

export const listAllPaymentGateways = () => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = listPaymentGatewaysConfig()
            const response = await axios.get(url, config)
            dispatch(setGateways(response?.data?.data))
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setBillsLoading(false))
        }
    }
}

export const getUserCards = () => {
    return async (dispatch) => {
        dispatch(setBillsLoading(true))
        try {
            const { url, config } = getUserCardsConfig()
            const response = await axios.get(url, config)
            dispatch(setUserCards(response?.data?.data))
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setBillsLoading(false))
        }
    }
}

export const getPayUrl = async (data, dispatch) => {
    dispatch(setBillsLoading(true))
    try {
        const { url, config } = getPayUrlConfig()
        const response = await axios.post(url, data, config)
        if (response.status === 200) {
            toast.success("Payment Url Generated Successfully")
            return response?.data?.data
        }
    } catch (error) {
        toast.error(getError(error))
    } finally {
        dispatch(setBillsLoading(false))
    }
}

export const cancelAgree = (userId) => {
	return async (dispatch) => {
		dispatch(setBillsLoading(true))
	  try {
		const { url, config } = cancelAgreeConfig();
		const response = await axios.post(url, {userId}, config);
		if (response.status === 200) {
		  toast.success('Agreement Cancelled Successfully');
		  const profileConfig = getProfile()
        const profileRes = await axios.get(profileConfig?.url, profileConfig.config)
        dispatch(
            authenticationSuccess({
                user: profileRes?.data?.data,
            })
        )
		}
	  } catch (error) {
		toast.error(getError(error));
	  } finally {
		dispatch(setBillsLoading(false))
	  }
	};
  };

  export const  refreshUser = () => {
	return async (dispatch) => {
		dispatch(setBillsLoading(true))
	  try {
		const profileConfig = getProfile()
        const profileRes = await axios.get(profileConfig?.url, profileConfig.config)
        dispatch(
            authenticationSuccess({
                user: profileRes?.data?.data,
            })
        )
	  } catch (error) {
		toast.error(getError(error));
	  } finally {
		dispatch(setBillsLoading(false))
	  }
	};
  };