import React, { PureComponent } from "react"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts"

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

export class ChartPie extends PureComponent {
    render() {
        const { data, colors } = this.props

        // Filter out data with null values
        const validData = data.filter(
            (entry) => entry.value !== null && !isNaN(entry.value)
        )

        // Check if all values are 0
        const allValuesZero = validData.every((entry) => entry.value === 0)

        // Use a placeholder if filtered data is empty or all values are 0
        const placeholderData = [{ name: "No Data", value: 1 }]
        const isDataEmpty = validData.length === 0 || allValuesZero
        const chartData = isDataEmpty ? placeholderData : validData
        const chartColors = isDataEmpty ? ["#28283d"] : colors

        return (
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width="100%" height="100%">
                    <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={45}
                        fill="#8884d8"
                        stroke="#1E1E2D"
                        strokeWidth={3}
                        dataKey="value"
                        startAngle={90}
                        endAngle={450}
                    >
                        {chartData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={chartColors[index % chartColors.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        )
    }
}
