export const TicketIcon = ({ fill = "#0bb783" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                id="vuesax_bulk_receipt-2"
                data-name="vuesax/bulk/receipt-2"
                transform="translate(-492 -380)"
            >
                <g id="receipt-2" transform="translate(492 380)">
                    <path
                        id="Vector"
                        d="M0,0H24V24H0Z"
                        fill="none"
                        opacity="0"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M17,5.04v9.92a8.2,8.2,0,0,1-.5,3.37.076.076,0,0,1-.02.04,1.042,1.042,0,0,1-.85.42,2.8,2.8,0,0,1-1.86-1.09,1.768,1.768,0,0,0-2.8.15L9.96,19.19A1.83,1.83,0,0,1,8.5,20a1.83,1.83,0,0,1-1.46-.81L6.02,17.84a1.759,1.759,0,0,0-2.78-.15l-.01.01C2.1,18.91,1.1,19.09.52,18.37a.076.076,0,0,1-.02-.04A8.2,8.2,0,0,1,0,14.96V5.04A8.2,8.2,0,0,1,.5,1.67c0-.01,0-.02.02-.03.57-.73,1.58-.55,2.71.66l.01.01a1.759,1.759,0,0,0,2.78-.15L7.04.81A1.83,1.83,0,0,1,8.5,0,1.83,1.83,0,0,1,9.96.81l1.01,1.34a1.768,1.768,0,0,0,2.8.15,2.8,2.8,0,0,1,1.86-1.09,1.052,1.052,0,0,1,.85.43c.02.01.02.02.02.03A8.2,8.2,0,0,1,17,5.04Z"
                        transform="translate(3 2)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <g id="Group" transform="translate(7.25 9.5)">
                        <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M8.75,1.5h-8A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h8A.755.755,0,0,1,9.5.75.755.755,0,0,1,8.75,1.5Z"
                            fill={fill}
                        />
                    </g>
                    <g
                        id="Group-2"
                        data-name="Group"
                        transform="translate(7.25 13)"
                    >
                        <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M6.75,1.5h-6A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h6A.755.755,0,0,1,7.5.75.755.755,0,0,1,6.75,1.5Z"
                            fill={fill}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
