import { axios, getDashboardConfig, getCustomModulesConfig } from "lib"
import { getMultiData, getCustomModules } from "store/Slices/dashboardSlice"

export const getDashboardMultiData = () => {
    return async (dispatch) => {
        try {
            const { url, config } = getDashboardConfig()
            const res = await axios.get(url, config)
            dispatch(getMultiData(res?.data?.data))
        } catch (e) {
            //
        }
    }
}

export const getCustomModulesData = () => {
    return async (dispatch) => {
        try {
            const { url, config } = getCustomModulesConfig();
            const res = await axios.get(url, config);
            dispatch(getCustomModules(res?.data?.data || [])); // Handle potential null data
        } catch (error) {
            console.error("Error fetching custom modules:", error); //Improved error handling
        }
    };
};
