export const BellFilledIcon = ({ fill = "#3699ff", size = 24 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 24 24"
        >
            <g
                id="vuesax_bulk_notification-bing"
                data-name="vuesax/bulk/notification-bing"
                transform="translate(-108 -188)"
            >
                <g id="notification-bing">
                    <path
                        id="Vector"
                        d="M16.96,14.28a2.625,2.625,0,0,1-1.64,1.58,22.229,22.229,0,0,1-3.31.82c-.11.02-.22.04-.33.05-.18.03-.36.05-.54.07-.22.03-.45.05-.68.07-.63.05-1.25.08-1.88.08s-1.28-.03-1.91-.09c-.27-.02-.53-.05-.79-.09l-.44-.06c-.11-.02-.22-.03-.33-.05a20.675,20.675,0,0,1-3.28-.81A2.655,2.655,0,0,1,.15,14.28,2.653,2.653,0,0,1,.41,12l1.13-1.88A4.093,4.093,0,0,0,2,8.44V6.58a6.575,6.575,0,1,1,13.15,0V8.44a3.956,3.956,0,0,0,.47,1.68L16.75,12A2.653,2.653,0,0,1,16.96,14.28Z"
                        transform="translate(111.44 190.05)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M.76,4.62A.76.76,0,0,1,0,3.86V.76a.76.76,0,0,1,1.52,0v3.1A.773.773,0,0,1,.76,4.62Z"
                        transform="translate(119.24 194.14)"
                        fill="#3699ff"
                    />
                    <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M5.65.01A3.014,3.014,0,0,1,2.82,2,2.965,2.965,0,0,1,.7,1.11,2.761,2.761,0,0,1,0,0C.13.02.26.03.4.05c.23.03.47.06.71.08.57.05,1.15.08,1.73.08S3.98.18,4.54.13c.21-.02.42-.03.62-.06Z"
                        transform="translate(117.18 208)"
                        fill={fill}
                    />
                    <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H24V24H0Z"
                        transform="translate(108 188)"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}
