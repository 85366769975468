import { Modal } from "components"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { editTicket, addTicketComments } from "store"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

const validationSchema = Yup.object().shape({})

export const FollowUp = ({ show, setShow }) => {
    const { users } = useSelector((state) => state?.users)
    const { t } = useTranslation("/Components/ns")

    const { departments } = useSelector((state) => state?.departments)

    const { ticket, detailsLoading, loading } = useSelector(
        (state) => state?.tickets
    )

    const initialValues = {
        followUpOn: ticket?.followUpOn,
        assignedTo: ticket?.assignedTo,
        departmentId: ticket?.departmentId,
        ticketPriority: ticket?.ticketPriority,
        pinTicket: ticket?.pinTicket,
    }

    const fields = [
        {
            type: "date",
            name: "followUpOn",
            placeholder: t("selectFollowupDate"),
            title: t("followupDate"),
        },
        {
            type: "select",
            name: "assignedTo",
            placeholder: t("selectAdmin"),
            options: users?.map((user) => ({
                label: user?.fullName ? user?.fullName : user?.email,
                value: user?.id,
            })),
            title: t("admin"),
        },
        {
            type: "select",
            name: "departmentId",
            placeholder: t("selectDepartment"),
            options: departments?.map((dept) => ({
                label: dept?.name,
                value: dept?.id,
            })),
            title: t("department"),
        },
        {
            type: "select",
            name: "ticketPriority",
            placeholder: t("selectPriority"),
            options: [
                { name: t("low"), value: 0 },
                { name: t("medium"), value: 1 },
                { name: t("high"), value: 2 },
            ]?.map((priority) => ({
                label: priority?.name,
                value: priority?.value,
            })),
            title: t("priority"),
        },
        {
            type: "switch",
            name: "pinTicket",
            title: t("pinTicket"),
        },
        {
            type: "textarea",
            name: "comment",
            title: t("comment"),
            placeholder: t("enterComment"),
        },
    ]

    const dispatch = useDispatch()

    return (
        <Modal
            heading={t("followup")}
            submitText={t("followup")}
            show={show}
            setShow={setShow}
            loading={detailsLoading || loading}
            fields={fields}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={async (values) => {
                const finalTicketValues = {
                    ...ticket,
                    followUpOn: moment(values?.followUpOn)?.toISOString(),
                    departmentId: values?.departmentId,
                    ticketPriority: Number(values?.ticketPriority),
                    pinTicket: values?.pinTicket,
                    followUpComment: values?.comment ? values?.comment : null,
                }
                await dispatch(editTicket({ data: finalTicketValues }))
                if (values?.comment) {
                    await dispatch(
                        addTicketComments({
                            ticketId: ticket?.id,
                            commentText: values?.comment,
                            isSticky: false,
                            isDraft: false,
                            ticketCommentAction: 2,
                            ticketCommentType: 1,
                        })
                    )
                }
                setShow(false)
            }}
        />
    )
}
