import { Modal } from "components"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { addTicketComments } from "store"
import { editTicket } from "store"

export const AssignTicket = ({ show, setShow, id }) => {
    const { users, onlineUsers } = useSelector((state) => state?.users)
    const { t } = useTranslation("/Components/ns")

    const { departments } = useSelector((state) => state?.departments)
    const { ticket } = useSelector((state) => state?.tickets)
    const [departmentId, setDepartmentId] = useState("")

    const dispatch = useDispatch()

    let usersData = []
    if (departmentId) {
        usersData = [{ label: t("autoAssign"), value: "" }]
        users
            ?.filter((user) => user?.departmentIds?.includes(departmentId))
            ?.forEach((user) => {
                const isOnline = onlineUsers?.find(
                    (admin) => admin?.userId === user?.id
                )
                    ? true
                    : false
                usersData.push({
                    value: user?.id,
                    label: user?.fullName
                        ? `${user?.fullName}${isOnline ? "   (Online)" : ""}`
                        : "N/A",
                    isActive: isOnline ? true : false,
                })
            })
    } else {
        usersData = [{ label: t("pleaseSelectDepartmentFirst"), value: "" }]
    }

    let departmentsData = [{ value: "", label: t("selectDepartment") }]
    departments?.forEach((departments) => {
        departmentsData.push({
            value: departments?.id,
            label: departments?.name,
        })
    })
    const ticketStatus = [
        t("active"),
        t("waiting"),
        t("closed"),
        t("closed"),
    ]?.map((el, idx) => ({
        label: el,
        value: idx,
    }))

    const fields = [
        {
            type: "select",
            name: "department",
            // placeholder: "Select Department",
            options: departmentsData,
            action: setDepartmentId,
            title: t("department"),
        },
        {
            type: "select",
            name: "assignedTo",
            // placeholder: "Please select department first",
            options: usersData?.sort((a, b) =>
                a?.isActive === b?.isActive ? 0 : a?.isActive ? -1 : 1
            ),
            title: t("admin"),
        },
        {
            type: "select",
            name: "ticketPriority",
            placeholder: t("selectPriority"),
            options: ["Low", "Normal", "High"].map((el, idx) => ({
                label: el,
                value: idx,
            })),
            title: t("priority"),
        },
        {
            name: "ticketStatus",
            title: t("staus"),
            type: "select",
            options: ticketStatus,
        },
        {
            type: "textarea",
            name: "comment",
            title: t("comment"),
            placeholder: t("enterComment"),
        },
    ]

    const initialValues = {
        assignedTo: ticket?.assignedTo,
        ticketStatus: ticket?.ticketStatus,
        ticketPriority: ticket?.ticketPriority,
        comment: "",
    }

    return (
        <Modal
            heading={t("assignTicket")}
            submitText={t("transferTicket")}
            show={show}
            setShow={setShow}
            fields={fields}
            initialValues={initialValues}
            // loading={detailsLoading || loading || usersLoading}
            handleSubmit={async (values) => {
                const finalTicketValues = {
                    ...ticket,
                    ticketStatus: Number(values?.ticketStatus),
                    ticketPriority: Number(values?.ticketPriority),
                    assignedTo: values?.assignedTo,
                    assignedToFullName: values?.assignedTo
                        ? ticket?.assignedToFullName
                        : "",
                }
                // Edit Ticket Assigned To
                await dispatch(editTicket({ data: finalTicketValues }))

                if (values?.comment) {
                    await dispatch(
                        addTicketComments({
                            ticketId: ticket?.id,
                            commentText: values?.comment,
                            isSticky: false,
                            isDraft: false,
                            ticketCommentAction: 1,
                            ticketCommentType: 1,
                        })
                    )
                }
                setShow(false)
            }}
        />
    )
}
