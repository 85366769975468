export const WalletIcon = ({ fill = "#0bb783" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            viewBox="0 0 45 45"
        >
            <g
                id="vuesax_bulk_wallet"
                data-name="vuesax/bulk/wallet"
                transform="translate(-108 -508)"
            >
                <g id="wallet" transform="translate(108 508)">
                    <path
                        id="Vector"
                        d="M14.438.972l-.056.131L8.944,13.722H3.6a8.956,8.956,0,0,0-3.6.731L3.281,6.615l.075-.169.113-.3a2.974,2.974,0,0,1,.15-.338C6.075.128,8.85-1.166,14.438.972Z"
                        transform="translate(9.75 3.753)"
                        fill={fill}
                        opacity="0.8"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M15.6,13.125a9.482,9.482,0,0,0-2.644-.375H0L5.437.131,5.494,0c.262.094.544.225.825.319l4.144,1.744a12.407,12.407,0,0,1,4.913,3.15c.169.225.319.431.469.675a2.853,2.853,0,0,1,.375.806,2.509,2.509,0,0,1,.169.487C16.875,8.775,16.575,10.706,15.6,13.125Z"
                        transform="translate(18.694 4.725)"
                        fill={fill}
                    />
                    <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M36.6,9.15v3.656c0,.375-.019.75-.038,1.106-.356,6.563-4.012,9.863-10.95,9.863H10.988a10.113,10.113,0,0,1-1.331-.094Q.712,23.091.094,14.138A10.591,10.591,0,0,1,0,12.806V9.15A9.176,9.176,0,0,1,5.55.731,8.956,8.956,0,0,1,9.15,0h18.3a9.482,9.482,0,0,1,2.644.375A9.187,9.187,0,0,1,36.6,9.15Z"
                        transform="translate(4.2 17.475)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M8.831,0,5.55,7.837A9.176,9.176,0,0,0,0,16.256V10.763A10.966,10.966,0,0,1,8.831,0Z"
                        transform="translate(4.2 10.369)"
                        fill={fill}
                        opacity="0.6"
                    />
                    <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M6.506,9.712v5.494A9.187,9.187,0,0,0,0,6.431C.975,4.013,1.275,2.081.787.487A2.509,2.509,0,0,0,.619,0,10.95,10.95,0,0,1,6.506,9.712Z"
                        transform="translate(34.294 11.419)"
                        fill={fill}
                        opacity="0.6"
                    />
                    <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,0H45V45H0Z"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}
